import moment from "moment"

import React, { useEffect, useState } from "react"
import { useMutation, useQuery } from "react-query"
import { getAllPosts, getSimilarPosts } from "../../service/postAPI"
import { getAllSources } from "../../service/sourceAPI"
import { getAllTopics } from "../../service/topicAPI"
import { getAllTotalContentOfMonth } from '../../service/contentWordpressAPI'
import socket from "../../service/socket.js"
import { createHistory } from "../../service/historyAPI"
import { useSelector } from "react-redux"
// import Statistical from "./Statistical"
// import ChartDisplay from '../Statistic/index'
import DashboardTable from "./DashboardTable/DashboardTable"
import { makeQueryString } from "../../core/helper"
import axios from "axios"
export const FollowTagId = "878aa7a3-8691-49b9-8018-2159a8b55175"
export const size = 10
export const ActionsType = {
  cancelHandle: "cancelHandle",
  handlePost: "handlePost",
}
export const PostType = {
  postedAt: "postedAt",
  totalReactions: "totalReactions",
  follow: "follow",
}
export const SourceType = {
  all: "all",
  facebook: "facebook",
  website: "website",
  youtube: "youtube",
  tiktok: "tiktok",
  telegram: "telegram",
};
export const cluster = [
  //{ name: "All", value: [] },	
  {
    name: "Chị Hiền không hiền", value: ['000b3125-7582-458b-9237-640555b7faec',
      '00409857-0244-400f-8d97-b06bf043d6ee',
      '0046cc59-424b-4cb9-aba0-bdf0149bb261',
      '00615258-5ab3-43e0-9bc1-6623005a9555']
  },
  {
    name: "Ác ôn có số má", value: ['0090a46e-6393-4280-822c-ea728daa4ff3',
      '00a0cb7f-75a0-45bd-b139-7b86678e7f06',
      '00cf92a6-f014-4c09-bcfd-8930bac53423']
  },
  {
    name: "Asiad 2023", value: ['00d1e112-5035-4721-aa52-d3328de8563b',
      '00fc19e1-d65a-4b9f-b0d0-1075fdfc7657',
      '01095a28-35fb-44cb-88d1-b18df24a93e2',
      '010c4484-1fb3-4a36-af86-eac8501ef14e',
      '010e6e33-0045-484a-9955-ce437b8bc8fa']
  }
]
const keyTotalTopic = `${process.env.REACT_APP_API_URL}/topic?page=1&pageSize=1`
const keyTotalPostFollow = `${process.env.REACT_APP_API_URL}/content?page=1&pageSize=1`
const keyTotalTodayTopic = `${process.env.REACT_APP_API_URL}/topic?page=1&pageSize=0&updatedAt=${moment()
  .startOf("day")
  .toISOString()}`
const keyTotalContentToday = `${process.env.REACT_APP_API_URL}/content?page=1&pageSize=1&fromDate=${moment()
  .startOf("day")
  .toISOString()}&toDate=${moment().endOf("day").toISOString()}`
const keyTotalFBFollow = `${process.env.REACT_APP_API_URL}/source?page=1&pageSize=0&type=FB_PAGE`
const keyTotalToday = `${process.env.REACT_APP_API_URL}/content/totalSource/dashboard`
const keyTotalWebFollow = `${process.env.REACT_APP_API_URL}/source?page=1&pageSize=0&type=WEBSITE`
const keySimilarPost = `${process.env.REACT_APP_API_URL}/content/outstanding`
const keyTotalContentPerDay = `${process.env.REACT_APP_API_URL}/content/statistics/monthly`
const defaultParams = {
  page: 0,
  total: 0,
  first: 0,
  desc: true,
  pageSize: size,
  userHandle: "notHandle",
}

const makeQuery = (params) => {
  const query = {
    ...params,
  }
  delete query.total
  delete query.first
  return makeQueryString({
    ...query,
    page: params.page + 1,
  })
}

const QuickProcess = (props) => {
  const token = useSelector((state) => state.user.token)
  const [lineOptions, setLineOptions] = useState(null)
  const [type, setType] = useState(PostType.postedAt)
  const [source, setSource] = useState(SourceType.all)
  const [params, setParams] = useState({
    ...defaultParams,
    sortBy: PostType.postedAt,
    // type: "FB_POST,WEBSITE_POST,YOUTUBE",
    toDate: new Date().toISOString(),
  })
  const totalTopic = useQuery(keyTotalTopic, (query) => getAllTopics({ query, token }))
  const totalContentPerDay = useQuery(keyTotalContentPerDay, (query) => getAllTotalContentOfMonth({ query, token }))
  const totalPostFollow = useQuery(keyTotalPostFollow, (query) => getAllTopics({ query, token }))
  const totalTodayTopic = useQuery(keyTotalTodayTopic, (query) => getAllTopics({ query, token }))
  const totalContentToday = useQuery(keyTotalContentToday, (query) => getAllPosts({ query, token }))
  const totalFBFollow = useQuery(keyTotalFBFollow, (query) => getAllSources({ query, token }))
  const totalPostToday = useQuery(keyTotalToday, (query) => getAllPosts({ query, token }))
  const totalWebFollow = useQuery(keyTotalWebFollow, (query) => getAllSources({ query, token }))

  // const similarPost = useQuery(keySimilarPost, (query) => getSimilarPosts(query, token))
  const keyPosts = `${process.env.REACT_APP_API_URL}/content?${makeQuery(params)}`

  let posts = useQuery(keyPosts, (query) => getAllPosts({ query, token }))
  const [selectedCluster, setSelectedCluster] = useState(" ");
  const [clusterContent, setClusterContent] = useState();

  const applyLightTheme = () => {
    const lineOptions = {
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    }
    setLineOptions(lineOptions)
  }

  const applyDarkTheme = () => {
    const lineOptions = {
      plugins: {
        legend: {
          labels: {
            color: "#ebedef",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#ebedef",
          },
          grid: {
            color: "rgba(160, 167, 181, .3)",
          },
        },
        y: {
          ticks: {
            color: "#ebedef",
          },
          grid: {
            color: "rgba(160, 167, 181, .3)",
          },
        },
      },
    }

    setLineOptions(lineOptions)
  }
  const handleChangeType = (type) => {
    setType(type)
    switch (type) {
      case PostType.postedAt:
        setParams({
          ...params,
          sortBy: PostType.postedAt,
        })
        break
      case PostType.follow:
        // setParams({
        //   ...params,
        //   tagIds: FollowTagId,
        // })
        break
      case PostType.totalReactions:
        setParams({
          ...params,
          sortBy: PostType.totalReactions,
          fromDate: moment().startOf("day").toISOString(),
          toDate: moment().endOf("day").toISOString(),
        })
        break
    }
  }
  const handleChangeSource = (source) => {
    setSource(source)
    const cloneParams = { ...params }
    switch (source) {
      case "all":
        delete cloneParams.type;
        setParams(cloneParams);
        break;
      case "facebook":
        setParams({
          ...params,
          type: "FB_POST",
        });
        break;
      case "website":
        setParams({
          ...params,
          type: "WEBSITE_POST",
        });
        break;
      case "youtube":
        setParams({
          ...params,
          type: "YOUTUBE",
        });
        break;
      case "tiktok":
        setParams({
          ...params,
          type: "TIKTOK",
        });
        break;
      case "telegram":
        setParams({
          ...params,
          type: "TELEGRAM",
        });
        break;
      case null:
        delete cloneParams.type;
        setParams(cloneParams);
        break;
    }
  }

  const handlePageChange = (e) => {
    console.log("e", e)
    if (typeof e === "string") {
      if (e.length > 0) {
        setParams({
          ...params,
          search: e,
        })
      } else {
        let cloneParams = { ...params }
        delete cloneParams.search
        setParams(cloneParams)
      }
    } else if (e?.filters) {
      let filter
      if (e.filters?.tagIds) {
        const filterValue = e.filters.tagIds?.value
        if (filterValue) {
          filter = { tagIds: filterValue }
          setParams({
            ...params,
            ...(e?.page && { page: e.page }),
            ...(e?.first && { first: e.first }),
            ...filter,
          })
        } else {
          const cloneParams = { ...params }
          delete cloneParams.tagIds
          setParams({
            ...cloneParams,
            ...(e?.page && { page: e.page }),
            ...(e?.first && { first: e.first }),
          })
        }
      }
    } else {
      setParams({
        ...params,
        page: e.page,
        first: e.first,
      })
    }
  }
  useEffect(() => {
    socket.on("update_dashboard", (data) => {
      // totalTopic.refetch();
      totalPostFollow.refetch()
      totalContentToday.refetch()
      totalContentPerDay.refetch()
      posts.refetch()
      if (data?.type === "FB_POST") {
        totalFBFollow.refetch()
        totalPostToday.refetch()
      } else {
        totalWebFollow.refetch()
        totalPostToday.refetch()
      }
    })
  }, [posts, totalContentToday, totalFBFollow, totalPostToday, totalPostFollow, totalWebFollow, totalContentPerDay, clusterContent])

  useEffect(() => {
    if (props.colorMode === "light") {
      applyLightTheme()
    } else {
      applyDarkTheme()
    }
  }, [props.colorMode])
  const addHistory = useMutation(createHistory, {
    onError: (e) => {
      console.log(e)
    },
  })
  useEffect(() => {
    addHistory.mutate({ newData: { screen: "Dashboard", description: "Xem danh dashboard" }, token })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  console.log(totalContentPerDay)
  useEffect(() => {
    // Fetch content when the selected cluster changes	
    if (selectedCluster == "All") {
      setClusterContent(null)
      return;
    }
    else {
      const fetchClusterContent = async () => {
        const result = cluster.find(item => item.name == selectedCluster);
        const contentIds = result?.value;
        console.log(contentIds);
        const contentResults = [];
        try {
          for (const contentId of contentIds) {
            await axios.get(`http://localhost:8000/api/content/${contentId}`)
              .then(res => {
                contentResults.push(res?.data?.doc)
                // console.log(res);	
              })
            // setTmpContent(response)	
            // contentResults.push(tmpContent);	
          }
          console.log(contentResults);
          setClusterContent({
            dataUpdatedAt: Date.now(), error: null, errorUpdateCount: 0, errorUpdatedAt: 0,
            failureCount: 0, isError: false, isFetched: true, isFetchedAfterMount: true, isFetching: false,
            isIdle: false, isLoading: false, isLoadingError: false, isPlaceholderData: false,
            isPreviousData: false,
            isRefetchError: false,
            isRefetching: false,
            isStale: true,
            isSuccess: true,
            status: "success", data: { code: 200, page: 1, pageSize: 1, total: contentIds.length, docs: contentResults }
          });
        } catch (error) {
          console.error(`Error fetching content: ${error}`);
        }
      };
      fetchClusterContent();
    }
  }, [selectedCluster]);
  console.log(clusterContent)
  console.log(posts)
  const handleFilterChange = (event) => {
    setSelectedCluster(event.target.value);
  };
  const handleClusterClick = (value) => {
    setSelectedCluster(value);
  }
  return (
    <div className="grid">
      <DashboardTable
        dataList={clusterContent ? clusterContent : posts}
        handleChangeType={handleChangeType}
        handleChangeSource={handleChangeSource}
        type={type}
        source={source}
        handlePageChange={handlePageChange}
        handleFilterChange={handleFilterChange}
        selectedCluster={selectedCluster}
        handleClusterClick={handleClusterClick}
        params={params}
      />
    </div>
  )
}

export default React.memo(QuickProcess)
