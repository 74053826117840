import React, { PureComponent, useEffect, useState } from 'react';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LineChart, Line } from 'recharts';
import axios from 'axios'
import moment from "moment"
import { Calendar } from "primereact/calendar"
import './style-statistic.scss'
import { AutoComplete } from 'primereact/autocomplete';
import { useHistory } from "react-router-dom"
import { createHistory } from "../../service/historyAPI"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { getAllTopics } from "../../service/topicAPI.js"
import { getAllSources } from "../../service/sourceAPI.js"
import { useDispatch, useSelector } from "react-redux"
export default function ChartDisplay() {

    const { token, userData } = useSelector((state) => state.user)
    const [sources, setSources] = useState([])
    const [categories, setCategories] = useState([])
    const [detailPost, setDetailPost] = useState(null)
    const [topics, setTopics] = useState([])
    const [filterTopics, setFilterTopics] = useState(null)
    const [filterSources, setFilterSources] = useState(null)
    const [filterCategories, setFilterCategories] = useState(null)
    const [data, setData] = useState();
    const [srcIdFilter, setSrcIdFilter] = useState({ id: "", name: "Tất cả nguồn", link: "Tất cả nguồn" });
    const [categoryIdFilter, setCategoryIdFilter] = useState({ id: "", name: "Tất cả nguồn"});
    const [topicIdFilter, setTopicIdFilter] = useState("");
    const [filterSourceOptions, setFilterSourceOptions] = useState([])
    const [filterTopicOptions, setFilterTopicOptions] = useState([])
    const [date, setDate] = useState([new Date(new Date().setDate(new Date().getDate() - 10)), new Date()])
    const [queryDate, setQueryDate] = useState(
        `&start=${moment().year(2023).utcOffset(7).startOf("year").toISOString()}&end=${moment().utcOffset(7).endOf("day").toISOString()}`,
    )
    const [sourceStat, setSourceStat] = useState();
    const [topicStat, setTopicStat] = useState();
    const [categoryStat, setCategoryStat] = useState();
    const history = useHistory()
    const [topic, setTopic] = useState({
        id: "",
        name: "Tất cả chủ đề",
    })
    const keyAllTopic = `${process.env.REACT_APP_API_URL}/topic`
    const keyAllSource = `${process.env.REACT_APP_API_URL}/source`
    const keyAllCategory = `${process.env.REACT_APP_API_URL}/category`
    const [topicQuery, setTopicQuery] = useState({
        id: "",
        name: "Tất cả chủ đề",
    })
    const [category, setCategory] = useState({
        id: "",
        name: "Tất cả thể loại",
    })
    const [source, setSource] = useState({
        id: "",
        name: "Tất cả nguồn",
        link: "Tất cả nguồn"
    })
    const [sourceQuery, setSourceQuery] = useState({
        id: "",
        name: "Tất cả nguồn",
        link: "Tất cả nguồn"
    })
    const getAllTopic = useQuery(keyAllTopic, (query) => getAllTopics({ query, token }), {
        onSuccess: (data) => {
            if (data) {
                setTopics(
                    data?.docs.map((p) => ({
                        id: p.id,
                        name: p.name,
                    })),
                )
            }
        },
    })
    const getAllSource = useQuery(keyAllSource, (query) => getAllSources({ query, token }), {
        onSuccess: (data) => {
            if (data) {
                setSources(
                    data?.docs.map((p) => ({
                        id: p.id,
                        name: p.name,
                        link: p.link
                    })),
                )
            }
        },
    })

    const getAllCategory = useQuery(keyAllCategory, (query) => getAllSources({ query, token }), {
        onSuccess: (data) => {
            if (data) {
                setCategories(
                    data?.doc.map((p) => ({
                        id: p.id,
                        name: p.name,
                    })),
                )
            }
        },
    })

    useEffect(() => {
        searchCategory(null)
    }, [categories])

    useEffect(() => {
        searchTopic(null)
    }, [topics])

    useEffect(() => {
        searchSource(null)
    }, [sources])

    useEffect(() => {
        fetchData();
    }, [srcIdFilter, topicIdFilter, categoryIdFilter, date])
    useEffect(() => {
        fetchDateData();
    }, [date])
    
    const filterRange = (e) => {
        setDate(e.value)
        if (e?.value[1]) {
            setQueryDate(
                `&start=${moment(e.value[0]).utcOffset(7).startOf("day").toISOString()}&end=${moment(e.value[1]).utcOffset(7)
                    .endOf("day")
                    .toISOString()}`,
            )
        } else {
            setQueryDate(
                `&start=${moment(e.value[0]).utcOffset(7).startOf("day").toISOString()}&end=${moment(e.value[0]).utcOffset(7)
                    .endOf("day")
                    .toISOString()}`,
            )
        }
    }

    const searchTopic = (event) => {
        setTimeout(() => {
            let _filterTopic
            if (!event?.query?.trim()?.length) {
                _filterTopic = [...topics]
            } else {
                _filterTopic = topics.filter((topic) => {
                    return topic.name.toLowerCase().includes(event.query.toLowerCase())
                })
            }
            _filterTopic = [..._filterTopic, { id: "", name: "Tất cả chủ đề" }]
            setFilterTopics(_filterTopic)
        }, 250)
    }
    const searchSource = (event) => {
        setTimeout(() => {
            let _filterSource
            if (!event?.query?.trim().length) {
                _filterSource = [...sources]
            } else {
                _filterSource = sources.filter((source) => {
                    return source.link.toLowerCase().includes(event.query.toLowerCase())
                })
            }
            _filterSource = [..._filterSource, { id: "", name: "Tất cả nguồn", link: "Tất cả nguồn" }]
            console.log(" loc source:    ", _filterSource)
            setFilterSources(_filterSource)
        }, 250)
    }

    const searchCategory = (event) => {
        setTimeout(() => {
            let _filterCategory
            if (!event?.query?.trim().length) {
                _filterCategory = [...categories]
            } else {
                _filterCategory = categories.filter((category) => {
                    return category.name.toLowerCase().includes(event.query.toLowerCase())
                })
            }
            _filterCategory = [..._filterCategory, { id: "", name: "Tất cả thể loại" }]
            console.log(" loc category:    ", _filterCategory)
            setFilterCategories(_filterCategory)
        }, 250)
    }

    const fetchData = async () => {// Define the API endpoint URL

        let query = '';
        query = 'sid=' + srcIdFilter.id + '&tid=' + topicIdFilter + '&cid=' + categoryIdFilter.id
        const apiUrl = `${process.env.REACT_APP_API_URL}/content/statistics/monthly/all?${query}${queryDate}`; // Replace with your API URL
        console.log('api:', apiUrl);
        // Make the Axios GET request
        axios
            .get(apiUrl)
            .then((response) => {
                // Handle the successful response here
                setData(response);
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error("Error fetching data:", error);
            });

    }

    const fetchDateData = async () => {
        const apiUrl1 = `${process.env.REACT_APP_API_URL}/content/statistics/source?${queryDate}`;
        axios.get(apiUrl1).then((res) => {
            setSourceStat(res?.data?.doc);
        }).catch((error) => {
            // Handle any errors that occurred during the request
            console.error("Error fetching data:", error);
        });

        const apiUrl2 = `${process.env.REACT_APP_API_URL}/content/statistics/topic?${queryDate}`;
        axios.get(apiUrl2).then((res) => {
            setTopicStat(res?.data?.doc);
        }).catch((error) => {
            // Handle any errors that occurred during the request
            console.error("Error fetching data:", error);
        });

        const apiUrl3 = `${process.env.REACT_APP_API_URL}/content/statistics/category?${queryDate}`;
        axios.get(apiUrl3).then((res) => {
            setCategoryStat(res?.data?.doc);
        }).catch((error) => {
            // Handle any errors that occurred during the request
            console.error("Error fetching data:", error);
        });

    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/source`)
            .then((response) => {
                // Handle the successful response here
                setFilterSourceOptions(response?.data?.docs);
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error("Error fetching data:", error);
            });

        axios.get(`${process.env.REACT_APP_API_URL}/topic`)
            .then((response) => {
                // Handle the successful response here
                setFilterTopicOptions(response?.data?.docs);
            })
            .catch((error) => {
                // Handle any errors that occurred during the request
                console.error("Error fetching data:", error);
            });
    }, [])


    console.log(srcIdFilter)
    console.log('e.value :  ', source);
    console.log("suggestions:   ", filterSources)
    return (
        <div className='main-statistic'>
            <div className='title-statistic'>Thống kê bài viết</div>
            <div className='main-chart'>
                <div className='main-select'>
                    <AutoComplete
                        value={source}
                        defaultValue={"Tất cả nguồn"}
                        suggestions={filterSources}
                        completeMethod={searchSource}
                        field="name"
                        dropdown
                        forceSelection
                        onSelect={(e) => {
                            console.log(e.value);
                            setSrcIdFilter(e.value)
                        }}
                        onChange={(e) => {
                            if (e.value) {
                                console.log(e.value);
                                // setSrcIdFilter(e.value.id)
                                setSource(e.value)
                            }
                            else {
                                setSource({
                                    id: "",
                                    name: "",
                                    link: ""
                                })
                            }
                        }}
                        aria-label="Sources"
                    />
                    <AutoComplete
                        value={category}
                        defaultValue={"Tất cả thể loại"}
                        suggestions={filterCategories}
                        completeMethod={searchCategory}
                        field="name"
                        dropdown
                        forceSelection
                        onSelect={(e) => {
                            console.log(e.value);
                            setCategoryIdFilter(e.value)
                        }}
                        onChange={(e) => {
                            if (e.value) {
                                console.log(e.value);
                                // setSrcIdFilter(e.value.id)
                                setCategory(e.value)
                            }
                            else {
                                setCategory({
                                    id: "",
                                    name: "",
                                })
                            }
                        }}
                        aria-label="Categories"
                    />
                    {/* <AutoComplete
                        value={filterTopicOptions.find(item => item.id === topicIdFilter)?.name || 'Tất cả chủ đề'}
                        suggestions={filterTopics}
                        completeMethod={searchTopic}
                        field="name"
                        dropdown
                        forceSelection
                        onSelect={(e) => {
                            setTopicIdFilter(e.value.id)
                        }}
                        onChange={(e) => {
                            if (e.value) {

                                setTopicIdFilter(e.value.id)
                            }
                        }}
                    /> */}
                </div>
                {(data && data.data && data.data.doc) ?
                    <LineChart
                        width={500}
                        height={300}
                        data={data?.data?.doc}
                        margin={{
                            top: 25,
                            right: 30,
                            left: 5,
                            bottom: 5,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <Line type="monotone" dataKey="total" stroke="#8884d8" activeDot={{ r: 8 }} />
                    </LineChart>
                    :
                    <div className='not-data'>Không có dữ liệu</div>
                }
            </div>
            <div className='main-table'>
                <div className="date-picker-table">
                    <Calendar
                        id="range"
                        dateFormat="dd/mm/yy"
                        value={date}
                        onChange={(e) => filterRange(e)}
                        selectionMode="range"
                        placeholder="DD/MM/YYYY - DD/MM/YYYY"
                        readOnlyInput
                        showIcon

                    />
                </div>
                <div className='tables'>
                    <div className='table-content'>
                        <div className='title-table'>Thống kê theo nguồn</div>
                        <div className='table-container'>
                            {sourceStat?.map((item) => (
                                <div className='table-item' key={item?.sourceid}>
                                    <div className='table-cells' style={{overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: 250, whiteSpace: 'nowrap'}}>{item?.sourcename}-<span className='italic-text' style={{fontStyle: 'italic'}}>{item?.category}</span></div>
                                    <div>{item?.totalcontentspertopic}</div>
                                </div>
                            ))}</div>

                    </div>
                    {/* <div className='table-content'>
                        <div className='title-table'>Thống kê theo chủ đề</div>
                        <div className='table-container'>
                            {topicStat?.map((item) => (
                                <div className='table-item' key={item?.topicid}>
                                    <div>{item?.topicname}</div>
                                    <div>{item?.totalcontentspertopic}</div>
                                </div>
                            ))}
                        </div>
                    </div> */}
                    <div className='table-content'>
                        <div className='title-table'>Thống kê theo thể loại</div>
                        <div className='table-container'>
                            {categoryStat?.map((item) => (
                                <div className='table-item' key={item?.name}>
                                    <div>{item?.name}</div>
                                    <div>{item?.totalcontentspertopic}</div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
}