import classNames from "classnames";
import { AppMenu } from "../../AppMenu";
import { AppTopbar } from "../../AppTopbar";
import DialogDetailContent from "../DialogDetailContent";
import React, { useEffect, useRef, useState } from "react";

import { useHistory, useLocation } from "react-router-dom";
import PrimeReact from "primereact/api";
import { Sidebar } from "primereact/sidebar";
import { CSSTransition } from "react-transition-group";
import { Tooltip } from "primereact/tooltip";
import { OverlayPanel } from "primereact/overlaypanel";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../store/userStore";
import axios from "axios";




const LayoutAdmin = ({ children }) => {
  const dispatch = useDispatch()
  const [displayDialog, setDisplayDialog] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [mobileTopbarMenuActive, setMobileTopbarMenuActive] = useState(false);
  const [layoutMode, setLayoutMode] = useState("static");
  const [layoutColorMode, setLayoutColorMode] = useState("light");
  const [inputStyle, setInputStyle] = useState("outlined");
  const [ripple, setRipple] = useState(true);
  const [staticMenuInactive, setStaticMenuInactive] = useState(false);
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  const [mobileMenuActive, setMobileMenuActive] = useState(false);
  const [noticeData, setNoticeData] = useState(null)
  const op = useRef();
  const op2 = useRef();
  const history = useHistory();
  const queryStr = useSelector(state => state.query.queryStr)
  const [counter, setCounter] = useState(0);
  const queryNotice = `${process.env.REACT_APP_API_URL}/source/content/lessContent`



  useEffect(() => {
    if (queryStr) {
      setTimeout(() => {
        setDisplayDialog(true);
      }, 300);
    } else {
      setDisplayDialog(false);
    }
  }, [queryStr]);

  useEffect(() => {
    const getInforNotice = axios.get(queryNotice).then((res) => {
      setNoticeData(res?.data?.doc);
      let c = 0;
      if (res?.data?.doc?.isWebsiteDecrease) c++;
      if (res?.data?.doc?.isFBDecrease) c++;
      if (res?.data?.doc?.isYTDecrease) c++;
      if (res?.data?.doc?.isNotFB) c++;
      if (res?.data?.doc?.isNotYT) c++;
      if (res?.data?.doc?.sourceWithoutContentToday?.length > 0) c++;
      setCounter(c);
    }).catch((error) => {
      // Handle any errors that occurred during the request
      console.error("Error fetching data:", error);
    });
  }, [])

  const copyTooltipRef = useRef();
  const location = useLocation();

  PrimeReact.ripple = true;

  let menuClick = false;
  let mobileTopbarMenuClick = false;

  useEffect(() => {
    if (mobileMenuActive) {
      addClass(document.body, "body-overflow-hidden");
    } else {
      removeClass(document.body, "body-overflow-hidden");
    }
  }, [mobileMenuActive]);

  useEffect(() => {
    copyTooltipRef && copyTooltipRef.current && copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  const isDesktop = () => {
    return window.innerWidth >= 992;
  };

  const menu = [
    {
      label: "Menu",
      icon: "pi pi-fw pi-sitemap",
      items: [
        { label: "Dashboard", icon: "pi pi-fw pi-home", to: "/" },
        //{ label: "Xử lý nhanh", icon: "pi pi-user", to: "/quick-process" },
        { label: "Chủ đề", icon: "pi pi-folder", to: "/chu-de" },
        { label: "Nguồn dữ liệu", icon: "pi pi-code", to: "/du-lieu" },
        { label: "Bài viết", icon: "pi pi-fw pi-bookmark", to: "/bai-viet" },
        { label: "Hồ sơ đối tượng", icon: "pi pi-users", to: "/nguoi-dang" },
        { label: "Quản lý bài viết cần xử lý", icon: "pi pi-exclamation-circle", to: "/quan-ly-bai-viet-can-xu-ly" },
        { label: "Chiến dịch facebook", icon: "pi pi-play", to: "/chien-dich-facebook" },
        { label: "Thẻ", icon: "pi pi-hashtag", to: "/tag" },
        { label: "Hồ sơ vụ việc", icon: "pi pi-book", to: "/ho-so" },
        { label: "Báo cáo", icon: "pi pi-download", to: "/bao-cao" },
        { label: "Facebook Account", icon: "pi pi-facebook", to: "/fb-account" },
        { label: "Setting thông báo", icon: "pi pi-send", to: "/thong-bao" },
        { label: "Trang thu thập thông tin", icon: "pi pi-align-justify", to: "/content" },
        { label: "Người dùng", icon: "pi pi-user", to: "/nguoi-dung" },
        // { label: "Nguồn bất thường", icon: "pi pi-info", to: "/nguon-bat-thuong" },
        // { label: "Thống kê", icon: "pi pi-chart-bar", to: "/statistic" },
      ],
    },
  ];

  const addClass = (element, className) => {
    if (element.classList) element.classList.add(className);
    else element.className += " " + className;
  };

  const removeClass = (element, className) => {
    if (element.classList) element.classList.remove(className);
    else element.className = element.className.replace(new RegExp("(^|\\b)" + className.split(" ").join("|") + "(\\b|$)", "gi"), " ");
  };

  const wrapperClass = classNames("layout-wrapper", {
    "layout-overlay": layoutMode === "overlay",
    "layout-static": layoutMode === "static",
    "layout-static-sidebar-inactive": staticMenuInactive && layoutMode === "static",
    "layout-overlay-sidebar-active": overlayMenuActive && layoutMode === "overlay",
    "layout-mobile-sidebar-active": mobileMenuActive,
    "p-input-filled": inputStyle === "filled",
    "p-ripple-disabled": ripple === false,
    "layout-theme-light": layoutColorMode === "light",
  });
  const onWrapperClick = (event) => {
    if (!menuClick) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }

    if (!mobileTopbarMenuClick) {
      setMobileTopbarMenuActive(false);
    }

    mobileTopbarMenuClick = false;
    menuClick = false;
  };

  const onToggleMenuClick = (event) => {
    menuClick = true;

    if (isDesktop()) {
      if (layoutMode === "overlay") {
        if (mobileMenuActive === true) {
          setOverlayMenuActive(true);
        }

        setOverlayMenuActive((prevState) => !prevState);
        setMobileMenuActive(false);
      } else if (layoutMode === "static") {
        setStaticMenuInactive((prevState) => !prevState);
      }
    } else {
      setMobileMenuActive((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarClick = () => {
    menuClick = true;
  };

  const onMobileTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;

    setMobileTopbarMenuActive((prevState) => !prevState);
    event.preventDefault();
  };

  const onMobileSubTopbarMenuClick = (event) => {
    mobileTopbarMenuClick = true;
    setSidebar(true);

    event.preventDefault();
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      setOverlayMenuActive(false);
      setMobileMenuActive(false);
    }
  };
  const onPopupUserLogout = (e) => {
    op.current.toggle(e);
  };
  const onPopupNotice = (e) => {
    op2.current.toggle(e);
  };
  const handleLogout = async () => {
    dispatch(logout())
    history.push({
      pathname: "/login",
    });
  };
  console.log(noticeData);

  console.log(counter);




  return (
    <div className={wrapperClass} onClick={onWrapperClick}>
      <DialogDetailContent id={queryStr} displayDialog={displayDialog} closeDialog={() => setDisplayDialog(false)} />
      <Tooltip ref={copyTooltipRef} target=".block-action-copy" position="bottom" content="Copied to clipboard" event="focus" />
      <OverlayPanel ref={op}>
        <ul className="list-none p-0 m-0">
          <li>
            <button className="p-link layout-topbar-button gap-2 flex" onClick={handleLogout}>
              <i className="pi pi-sign-out" />
              <span>Logout</span>
            </button>
          </li>
        </ul>
      </OverlayPanel>

      <OverlayPanel ref={op2}>
        <div className="notice">
          {!noticeData?.isWebsiteDecrease && !noticeData?.isNotYT && !noticeData?.isNotFB && !noticeData?.isYTDecrease && !noticeData?.isFBDecrease && noticeData?.sourceWithoutContentToday?.length == 0 ?
            <div className="no-notice" style={{ maxHeight: 100, overflow: 'auto', display: "block", borderRadius: 6 }}>Không có thông báo</div> :
            <div className="have-notice" style={{ maxHeight: 100, overflow: 'auto', display: "block", borderRadius: 6 }}>
              {
                noticeData?.isWebsiteDecrease ?
                  <div className="web-deacrease" style={{ padding: 15, backgroundColor: '#edf1f5', borderRadius: 6, marginBottom: 10 }}>
                    Các bài viết từ Website giảm bất thường
                  </div> :
                  <></>
              }
              {
                noticeData?.isNotYT ?
                  <div className="web-deacrease" style={{ padding: 15, backgroundColor: '#edf1f5', borderRadius: 6, marginBottom: 10 }}>
                    Không có bài viết từ Facebook 1 ngày qua
                  </div> :
                  <></>
              }
              {
                noticeData?.isNotFB ?
                  <div className="web-deacrease" style={{ padding: 15, backgroundColor: '#edf1f5', borderRadius: 6, marginBottom: 10 }}>
                    Không có bài viết từ Youtube 1 ngày qua
                  </div> :
                  <></>
              }
              {
                noticeData?.isYTDecrease ?
                  <div className="yt-deacrease" style={{ padding: 15, backgroundColor: '#edf1f5', borderRadius: 6, marginBottom: 10 }}>
                    Các bài viết từ Youtube giảm bất thường
                  </div> :
                  <></>
              }
              {
                noticeData?.isFBDecrease ?
                  <div className="fb-deacrease" style={{ padding: 15, backgroundColor: '#edf1f5', borderRadius: 6, marginBottom: 10 }}>
                    Các bài viết từ Facebook giảm bất thường
                  </div> :
                  <></>
              }
              {
                noticeData?.sourceWithoutContentToday && noticeData?.sourceWithoutContentToday.length > 0 ?
                  <div className="webs-no-content" style={{ padding: 15, backgroundColor: '#edf1f5', borderRadius: 6, marginBottom: 10 }}>
                    Có {noticeData.sourceWithoutContentToday.length} nguồn báo không có bài viết từ 24h qua
                    <span
                      style={{ textDecoration: 'underline', cursor: 'pointer', marginLeft: 5, color: 'red'}}
                      onClick={() => history.push('/nguon-bat-thuong')}
                    >
                      Xem chi tiết
                    </span>
                  </div> :
                  <></>
              }
            </div>
          }
        </div>
      </OverlayPanel>

      <AppTopbar counter={counter} onToggleMenuClick={onToggleMenuClick} layoutColorMode={layoutColorMode} onPopupUserLogout={onPopupUserLogout} onPopupNotice={onPopupNotice} mobileTopbarMenuActive={mobileTopbarMenuActive} onMobileTopbarMenuClick={onMobileTopbarMenuClick} onMobileSubTopbarMenuClick={onMobileSubTopbarMenuClick} />

      <div className="layout-sidebar" onClick={onSidebarClick}>
        <AppMenu model={menu} onMenuItemClick={onMenuItemClick} layoutColorMode={layoutColorMode} />
      </div>

      <div className="layout-main-container">
        <div className="layout-main">{children && children}</div>
      </div>
      <Sidebar visible={sidebar} className="p-sidebar-md" position="right" onHide={() => setSidebar(false)}>
        Content
      </Sidebar>

      <CSSTransition classNames="layout-mask" timeout={{ enter: 200, exit: 200 }} in={mobileMenuActive} unmountOnExit>
        <div className="layout-mask p-component-overlay"></div>
      </CSSTransition>
    </div>
  );
};

export default LayoutAdmin;
