const Statistical = ({
  totalTopic,
  totalTodayTopic,
  totalPostFollow,
  totalContentToday,
  totalFBFollow,
  totalYTFollow,
  totalPostToday,
  totalWebFollow,
  categoryData,
  noticeData
}) => {

  console.log(totalPostFollow);

  return (
    <>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Chủ đề</span>
              <div className="text-900 font-medium text-xl">
                {Number(totalTopic?.data?.total || 0).toLocaleString('vi')}
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-cyan-100 border-round"
              style={{width: '2.5rem', height: '2.5rem'}}
            >
              <i className="pi pi-tags text-blue-500 text-xl" />
            </div>
          </div>
          <span className="text-green-500 font-medium">
            {Number(totalTodayTopic?.data?.total || 0).toLocaleString('vi')} chủ đề{' '}
          </span>
          <span className="text-500">được cập nhật hôm nay</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Thể loại</span>
              <div className="text-900 font-medium text-xl">
                {Number(categoryData?.total || 0).toLocaleString('vi')}
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-yellow-100 border-round"
              style={{width: '2.5rem', height: '2.5rem'}}
            >
              <i className="pi pi-folder text-yellow-500 text-xl" />
            </div>
          </div>
          <span className="text-green-500 font-medium">
            {Number(categoryData?.today || 0).toLocaleString('vi')} thể loại{' '}
          </span>
          <span className="text-500">có bài viết mới hôm nay</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Bài viết</span>
              <div className="text-900 font-medium text-xl">
                {Number(totalPostFollow?.data?.total || 0).toLocaleString('vi')} được theo dõi
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-orange-100 border-round"
              style={{width: '2.5rem', height: '2.5rem'}}
            >
              <i className="pi pi-hashtag text-orange-500 text-xl" />
            </div>
          </div>
          <span className="text-green-500 font-medium">
            {Number(totalContentToday?.data?.total || 0).toLocaleString('vi')} bài viết{' '}
          </span>
          <span className="text-500">mới hôm nay</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Cụm tin</span>
              <div className="text-900 font-medium text-xl">
                {(100).toLocaleString('vi')} cụm đã được gom
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-pink-100 border-round"
              style={{width: '2.5rem', height: '2.5rem'}}
            >
              <i className="pi pi-sitemap text-gray-500 text-xl" />
            </div>
          </div>
          <span className="text-green-500 font-medium">
            {(50).toLocaleString('vi')} cụm tin{' '}
          </span>
          <span className="text-500"> mới hôm nay</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Trang Facebook</span>
              <div className="text-900 font-medium text-xl">
                {Number(totalFBFollow?.data?.total || 0).toLocaleString('vi')} được theo dõi
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-crimson-100 border-round"
              style={{width: '2.5rem', height: '2.5rem'}}
            >
              <i className="pi pi-facebook text-blue-500 text-xl" />
            </div>
          </div>
          <span className="text-green-500 font-medium">
            {Number(totalPostToday?.data?.doc?.totalFacebookSource || 0).toLocaleString('vi')} trang{' '}
          </span>
          <span className="text-500">có bài viết mới hôm nay</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Kênh Youtube</span>
              <div className="text-900 font-medium text-xl">
                {Number(totalYTFollow?.data?.total || 0).toLocaleString('vi')} được theo dõi
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-crimson-100 border-round"
              style={{width: '2.5rem', height: '2.5rem'}}
            >
              <i className="pi pi-youtube text-maroon-500 text-xl" style={{color: 'red'}} />
            </div>
          </div>
          <span className="text-green-500 font-medium">
            {Number(totalPostToday?.data?.doc?.totalYoutubeSource || 0).toLocaleString('vi')} kênh{' '}
          </span>
          <span className="text-500">có bài viết mới hôm nay</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Trang tin tức</span>
              <div className="text-900 font-medium text-xl">
                {Number(totalWebFollow?.data?.total || 0).toLocaleString('vi')} được theo dõi
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-maroon-100 border-round"
              style={{width: '2.5rem', height: '2.5rem'}}
            >
              <i className="pi pi-globe text-purple-500 text-xl" />
            </div>
          </div>
          <span className="text-green-500 font-medium">
            {Number(totalPostToday?.data?.doc?.totalWebsiteSource || 0).toLocaleString('vi')} trang{' '}
          </span>
          <span className="text-500">có bài viết mới hôm nay</span>
        </div>
      </div>
      <div className="col-12 lg:col-6 xl:col-3">
        <div className="card mb-0">
          <div className="flex justify-content-between mb-3">
            <div>
              <span className="block text-500 font-medium mb-3">Nguồn bài viết</span>
              <div className="text-900 font-medium text-xl">
                {Number(totalWebFollow?.data?.total || 0).toLocaleString('vi')} được theo dõi
              </div>
            </div>
            <div
              className="flex align-items-center justify-content-center bg-maroon-100 border-round"
              style={{width: '2.5rem', height: '2.5rem'}}
            >
              <i className="pi pi-info-circle text-maroon-500 text-xl" style={{color: 'red'}}/>
            </div>
          </div>
          <span className="" style={{color: 'red', fontWeight: 500}}>
            {Number(noticeData?.sourceWithoutContentToday?.length || 0).toLocaleString('vi')} trang{' '}
          </span>
          <span className="text-500" style={{}}>hiện không hoạt động</span>
        </div>
      </div>
      
    </>
  )
}

export default Statistical
