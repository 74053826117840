import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable"
import { Column } from 'primereact/column';
import { createHistory } from "../../service/historyAPI.js"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { deleteSource, getAllSources, updateSource } from "../../service/sourceAPI.js"
import { Button } from "primereact/button"
import { Dialog } from "primereact/dialog"
import { useSelector } from "react-redux"
export default function AbnormalSource() {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const queryNotice = `${process.env.REACT_APP_API_URL}/source/details/aiiDetails`
    const [noticeData, setNoticeData] = useState(null)
    const [categoryData, setCategoryData] = useState(null);
    const [source, setSource] = useState(null)
    const [deleteSourceDialog, setDeleteSourcenDialog] = useState(false)
    const toast = useRef(null)
    const queryClient = useQueryClient()
    const token = useSelector((state) => state.user.token)

    useEffect(() => {
        const getInforNotice = axios.get(queryNotice).then((res) => {
            setNoticeData(res?.data?.doc);
        }).catch((error) => {
            // Handle any errors that occurred during the request
            console.error("Error fetching data:", error);
        });
    }, [source])
    console.log(noticeData)
    const handleError = (err) => {
        if (err?.response?.data?.msg) {
            toast.current.show({ severity: "error", summary: err.response.data.msg, detail: "Lỗi" })
            throw new Error(err.response.data.msg)
        } else if (err?.message) {
            toast.current.show({ severity: "error", summary: err.message, detail: "Lỗi" })
            throw new Error(err.message)
        } else {
            toast.current.show({ severity: "error", summary: err, detail: "Lỗi" })
        }
    }
    const remove = useMutation(deleteSource, {
        onSuccess: () => {
            toast.current.show({ severity: "success", summary: "Xóa nguồn dữ liệu thành công", detail: "Thành công" })
            addHistory.mutate({
                newData: {
                    screen: "Chủ đề",
                    description: `Xóa nguồn có thông tin: { id: ${source?.id}, name: ${source?.name}, link: ${source?.link} , type: ${source?.type}, status: ${source?.status} }`,
                },
                token,
            })
            setSource({})
        },
        onError: (error) => handleError(error),
        onSettled: () =>
            queryClient.invalidateQueries({
                predicate: (query) => query.queryKey.startsWith(`${process.env.REACT_APP_API_URL}/source`),
            }),
    })
    const addHistory = useMutation(createHistory, {
        onError: (e) => {
            console.log(e)
        },
    })
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentData = noticeData?.slice(startIndex, endIndex);
    const totalPages = Math.ceil(noticeData?.length / itemsPerPage);

    const onPageChange = (event) => {
        setCurrentPage(event.page + 1);
    };
    const confirmDeleteSource = (source) => {
        setSource(source)
        setDeleteSourcenDialog(true)
    }
    const deleteSourceConfirm = () => {
        remove.mutate({ id: source.source_id, token })
        window.location.reload();
        setDeleteSourcenDialog(false)
    }
    const hideDeleteSourceDialog = () => {
        setDeleteSourcenDialog(false)
    }
    const actionBodyTemplate = (rowData) => {
        return (
            <React.Fragment>
                <div className="text-center flex gap-2 justify-content-center" style={{ color: "red" }}>
                    <i title="Xóa" className="pi pi-trash cursor-pointer" onClick={() => confirmDeleteSource(rowData)}></i>
                    {/* <Button icon="pi pi-trash" className="p-button-danger" onClick={() => confirmDeleteCampaign(rowData)} /> */}
                </div>
            </React.Fragment>
        )
    }
    const deleteSourceDialogFooter = (
        <React.Fragment>
            <Button label="Hủy" icon="pi pi-times" className="p-button-text" onClick={hideDeleteSourceDialog} />
            <Button label="Đồng ý" icon="pi pi-check" className="p-button-text" onClick={deleteSourceConfirm} />
        </React.Fragment>
    )
    return (
        <div>
            <DataTable
                value={noticeData}
                paginator
                rows={itemsPerPage}
                totalRecords={noticeData?.length}
                onPageChange={onPageChange}
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
                // filters={filters}
                filterDisplay="row"
                // loading={loading}  
                style={{ boderRadius: 10 }}
            >
                <Column
                    body={(data, rowIndex) => noticeData?.indexOf(data) + 1
                    }
                    header="STT"
                    style={{ width: '5%', textAlign: 'center' }}

                />
                <Column
                    field="source_name" header="Tên nguồn"
                    style={{ width: '15%' }}

                />
                {/* <Column field="source_link" header="Đường dẫn" 
                headerStyle={{ width: '100px' }}
                style={{width: '100px'}} /> */}
                <Column
                    field="category_name" header="Thể loại"
                    sortable
                    filter
                    filterPlaceholder="Nhập thể loại"
                    style={{ width: '20%' }}
                />
                <Column
                    field="type" header="Loại nguồn"
                    filter
                    style={{ width: '20%' }}
                    filterPlaceholder="Nhập loại nguồn"
                    sortable />
                <Column
                    field="status" header="Trạng thái"
                    style={{ width: '10%' }}
                    sortable />
                <Column
                    field="ranking" header="Xếp hạng"
                    style={{ width: '10%' }}
                    sortable />
                <Column
                    field="last_crawl" header="Lần có bài gần nhất"
                    style={{ width: '30%' }}
                    sortable />
                <Column
                    header="Hành động"
                    alignHeader="center"
                    body={actionBodyTemplate}
                    exportable={false}
                    style={{ minWidth: "5%" }}
                ></Column>
            </DataTable>
            <Dialog
                visible={deleteSourceDialog}
                dismissableMask
                style={{ width: "450px" }}
                header="Xác nhận"
                modal
                footer={deleteSourceDialogFooter}
                onHide={hideDeleteSourceDialog}
            >
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: "2rem" }} />
                    {source && (
                        <span>
                            Bạn có chắc muốn xóa <b>{source.source_name}</b>, các bài viết từ nguồn dữ liệu này cũng sẽ bị xóa?
                        </span>
                    )}
                </div>
            </Dialog>
        </div>
    )
}