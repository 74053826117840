import moment from "moment";

import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { getAllPosts, getSimilarPosts } from "../../service/postAPI";
import { getAllSources } from "../../service/sourceAPI";
import { getAllTopics } from "../../service/topicAPI";
import { getAllTotalContentOfMonth } from "../../service/contentWordpressAPI";
import socket from "../../service/socket.js";
import { createHistory } from "../../service/historyAPI";
import { useSelector } from "react-redux";
import Statistical from "./Statistical";
import ChartDisplay from "../Statistic/index";
import DashboardTable from "./DashboardTable/DashboardTable";
import { makeQueryString } from "../../core/helper";
import axios from "axios";
import jsonData from "./../cluster.json";

export const FollowTagId = "878aa7a3-8691-49b9-8018-2159a8b55175";
export const size = 10;
export const ActionsType = {
  cancelHandle: "cancelHandle",
  handlePost: "handlePost",
};
export const PostType = {
  postedAt: "postedAt",
  totalReactions: "totalReactions",
  follow: "follow",
};
export const SourceType = {
  all: "all",
  facebook: "facebook",
  website: "website",
  youtube: "youtube",
  tiktok: "tiktok",
  telegram: "telegram",
};

export const cluster = [
  //{ name: "All", value: [] },
  // {
  //   name: "Asiad 2023", value: ['cfda3e7c-0d38-4fe8-9945-58b734ffeae0',
  //     'f5588434-a113-46dd-ae39-736c66bd9b1d',
  //     '41adf050-6e0c-42b5-9e09-4b1a40e0c2a7',
  //     '283ab463-0c52-4421-86ca-8323a10cfddf']
  // },
  // {
  //   name: "Xe mất phanh", value: ['6f7dfc19-d116-4015-bea2-9b43d0b71012',
  //     'e8449ca6-4af9-42b2-b989-75fcac69d237',
  //     'f4037661-7b96-4126-aa37-d15c223261d4']
  // },
  // {
  //   name: "Giết người chặt xác", value: ['47773cc4-edef-4289-9039-5d6d066fc262',
  //     '4875355b-a15a-4042-8173-2fc016b15d1e',
  //     '283bf493-fcbb-4e8a-babd-21a8354174ee',
  //     '652c8b92-d50d-421a-a572-01677e2ea18a',
  //     'c80f455d-35d7-4e0d-99fc-3053afc21b89']
  // }
];
const keyTotalTopic = `${process.env.REACT_APP_API_URL}/topic?page=1&pageSize=1`;
const keyTotalPostFollow = `${process.env.REACT_APP_API_URL}/content?page=1&pageSize=1`;
const keyTotalTodayTopic = `${process.env.REACT_APP_API_URL}/topic?page=1&pageSize=0&updatedAt=${moment().startOf("day").toISOString()}`;
const keyTotalContentToday = `${process.env.REACT_APP_API_URL}/content?page=1&pageSize=1&fromDate=${moment().startOf("day").toISOString()}&toDate=${moment().endOf("day").toISOString()}`;
const keyTotalFBFollow = `${process.env.REACT_APP_API_URL}/source?page=1&pageSize=0&type=FB_PAGE`;
const keyTotalYTFollow = `${process.env.REACT_APP_API_URL}/source?page=1&pageSize=0&type=YOUTUBE`;
const keyTotalToday = `${process.env.REACT_APP_API_URL}/content/totalSource/dashboard`;
const keyTotalWebFollow = `${process.env.REACT_APP_API_URL}/source?page=1&pageSize=0&type=WEBSITE`;
const keySimilarPost = `${process.env.REACT_APP_API_URL}/content/outstanding`;
const keyTotalContentPerDay = `${process.env.REACT_APP_API_URL}/content/statistics/monthly`;
const defaultParams = {
  page: 0,
  total: 0,
  first: 0,
  desc: true,
  pageSize: size,
  userHandle: "notHandle",
};

const makeQuery = (params) => {
  const query = {
    ...params,
  };
  delete query.total;
  delete query.first;
  return makeQueryString({
    ...query,
    page: params.page + 1,
  });
};

function App() {
  const tableData = [];
  Object.keys(jsonData).forEach((key) => {
    const clusterinfo = jsonData[key];
    const avgStd = clusterinfo["avgStd"];
    const tencum = clusterinfo["articles"][0]["title"];
    for (let i = 0; i < clusterinfo["articles"].length; i++) {
      if (i == 0) {
        tableData.push({
          tencum: tencum,
          avgStd: avgStd,
          zscore: 0,
          title: clusterinfo["articles"][i]["title"],
          link: "",
        });
      } else {
        tableData.push({
          tencum: tencum,
          avgStd: avgStd,
          zscore: clusterinfo["zscore"][i - 1],
          title: clusterinfo["articles"][i]["title"],
          link: clusterinfo["articles"][i]["link"],
        });
      }
    }
  });
  console.log(tableData);
  return (
    <div>
      <table style={{ border: "1px solid #000", width: "100%" }}>
        <thead>
          <tr>
            <th style={tableHeaderStyle}>Tên Cụm</th>
            <th style={tableHeaderStyle}>Avg Std</th>
            <th style={tableHeaderStyle}>Z-Score</th>
            <th style={tableHeaderStyle}>Tiêu đề</th>
            <th style={tableHeaderStyle}>Đường dẫn</th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <tr key={index}>
              <td style={tableCellStyle}>{item.tencum || "Unknown"}</td>
              <td style={tableCellStyle}>{item.avgStd}</td>
              <td style={tableCellStyle}>{item.zscore}</td>
              <td style={tableCellStyle}>{item.title}</td>
              <td style={tableCellStyle}>
                <a href={item.link} target="_blank" rel="noopener noreferrer">
                  {item.link}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

const tableHeaderStyle = {
  backgroundColor: "#333",
  color: "white",
  textAlign: "center",
  padding: "8px",
};

const tableCellStyle = {
  border: "1px solid #000",
  textAlign: "center",
  padding: "8px",
};

const Dashboard = (props) => {
  const token = useSelector((state) => state.user.token);
  const [lineOptions, setLineOptions] = useState(null);
  const [type, setType] = useState(PostType.postedAt);
  const [source, setSource] = useState(SourceType.all);
  const [params, setParams] = useState({
    ...defaultParams,
    sortBy: PostType.postedAt,
    // type: "FB_POST,WEBSITE_POST,YOUTUBE",
    toDate: new Date().toISOString(),
  });
  const totalTopic = useQuery(keyTotalTopic, (query) => getAllTopics({ query, token }));
  const totalContentPerDay = useQuery(keyTotalContentPerDay, (query) => getAllTotalContentOfMonth({ query, token }));
  const totalPostFollow = useQuery(keyTotalPostFollow, (query) => getAllTopics({ query, token }));
  const totalTodayTopic = useQuery(keyTotalTodayTopic, (query) => getAllTopics({ query, token }));
  const totalContentToday = useQuery(keyTotalContentToday, (query) => getAllPosts({ query, token }));
  const totalFBFollow = useQuery(keyTotalFBFollow, (query) => getAllSources({ query, token }));
  const totalYTFollow = useQuery(keyTotalYTFollow, (query) => getAllSources({ query, token }));
  const totalPostToday = useQuery(keyTotalToday, (query) => getAllPosts({ query, token }));
  const totalWebFollow = useQuery(keyTotalWebFollow, (query) => getAllSources({ query, token }));

  // const similarPost = useQuery(keySimilarPost, (query) => getSimilarPosts(query, token))
  const keyPosts = `${process.env.REACT_APP_API_URL}/content?${makeQuery(params)}`;

  let posts = useQuery(keyPosts, (query) => getAllPosts({ query, token }));
  const [clusterData, setClusterData] = useState(null);
  const [selectedCluster, setSelectedCluster] = useState(" ");
  const [clusterContent, setClusterContent] = useState();

  const applyLightTheme = () => {
    const lineOptions = {
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };
    setLineOptions(lineOptions);
  };
  useEffect(() => {
    setClusterData(jsonData);
    console.log(clusterData);
    console.log(jsonData);
  }, []);
  const applyDarkTheme = () => {
    const lineOptions = {
      plugins: {
        legend: {
          labels: {
            color: "#ebedef",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#ebedef",
          },
          grid: {
            color: "rgba(160, 167, 181, .3)",
          },
        },
        y: {
          ticks: {
            color: "#ebedef",
          },
          grid: {
            color: "rgba(160, 167, 181, .3)",
          },
        },
      },
    };

    setLineOptions(lineOptions);
  };
  const handleChangeType = (type) => {
    setType(type);
    switch (type) {
      case PostType.postedAt:
        setParams({
          ...params,
          sortBy: PostType.postedAt,
        });
        break;
      case PostType.follow:
        // setParams({
        //   ...params,
        //   tagIds: FollowTagId,
        // })
        break;
      case PostType.totalReactions:
        setParams({
          ...params,
          sortBy: PostType.totalReactions,
          fromDate: moment().startOf("day").toISOString(),
          toDate: moment().endOf("day").toISOString(),
        });
        break;
    }
  };
  const handleChangeSource = (source) => {
    setSource(source);
    const cloneParams = { ...params };
    switch (source) {
      case "all":
        delete cloneParams.type;
        setParams(cloneParams);
        break;
      case "facebook":
        setParams({
          ...params,
          type: "FB_POST",
        });
        break;
      case "website":
        setParams({
          ...params,
          type: "WEBSITE_POST",
        });
        break;
      case "youtube":
        setParams({
          ...params,
          type: "YOUTUBE",
        });
        break;
      case "tiktok":
        setParams({
          ...params,
          type: "TIKTOK",
        });
        break;
      case "telegram":
        setParams({
          ...params,
          type: "TELEGRAM",
        });
        break;
      case null:
        delete cloneParams.type;
        setParams(cloneParams);
        break;
    }
  };

  const handlePageChange = (e) => {
    console.log("e", e);
    if (typeof e === "string") {
      if (e.length > 0) {
        setParams({
          ...params,
          search: e,
        });
      } else {
        let cloneParams = { ...params };
        delete cloneParams.search;
        setParams(cloneParams);
      }
    } else if (e?.filters) {
      let filter;
      if (e.filters?.tagIds) {
        const filterValue = e.filters.tagIds?.value;
        if (filterValue) {
          filter = { tagIds: filterValue };
          setParams({
            ...params,
            ...(e?.page && { page: e.page }),
            ...(e?.first && { first: e.first }),
            ...filter,
          });
        } else {
          const cloneParams = { ...params };
          delete cloneParams.tagIds;
          setParams({
            ...cloneParams,
            ...(e?.page && { page: e.page }),
            ...(e?.first && { first: e.first }),
          });
        }
      }
    } else {
      setParams({
        ...params,
        page: e.page,
        first: e.first,
      });
    }
  };
  useEffect(() => {
    socket.on("update_dashboard", (data) => {
      // totalTopic.refetch();
      totalPostFollow.refetch();
      totalContentToday.refetch();
      totalContentPerDay.refetch();
      posts.refetch();
      if (data?.type === "FB_POST") {
        totalFBFollow.refetch();
        totalPostToday.refetch();
      } else if (data?.type === "YOUTUBE") {
        totalYTFollow.refetch();
        totalPostToday.refetch();
      } else {
        totalWebFollow.refetch();
        totalPostToday.refetch();
      }
    });
  }, [posts, totalContentToday, totalFBFollow, totalYTFollow, totalPostToday, totalPostFollow, totalWebFollow, totalContentPerDay, clusterContent]);

  useEffect(() => {
    if (props.colorMode === "light") {
      applyLightTheme();
    } else {
      applyDarkTheme();
    }
  }, [props.colorMode]);
  const addHistory = useMutation(createHistory, {
    onError: (e) => {
      console.log(e);
    },
  });
  useEffect(() => {
    addHistory.mutate({ newData: { screen: "Dashboard", description: "Xem danh dashboard" }, token });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  console.log(totalContentPerDay);
  useEffect(() => {
    // Fetch content when the selected cluster changes
    if (selectedCluster == "All") {
      setClusterContent(null);
      return;
    } else {
      const fetchClusterContent = async () => {
        const result = cluster.find((item) => item.name == selectedCluster);
        const contentIds = result?.value;
        console.log(contentIds);
        const contentResults = [];
        try {
          for (const contentId of contentIds) {
            await axios.get(`${process.env.REACT_APP_API_URL}/content/${contentId}`).then((res) => {
              contentResults.push(res?.data?.doc);
              // console.log(res);
            });
            // setTmpContent(response)
            // contentResults.push(tmpContent);
          }
          console.log(contentResults);
          setClusterContent({
            dataUpdatedAt: Date.now(),
            error: null,
            errorUpdateCount: 0,
            errorUpdatedAt: 0,
            failureCount: 0,
            isError: false,
            isFetched: true,
            isFetchedAfterMount: true,
            isFetching: false,
            isIdle: false,
            isLoading: false,
            isLoadingError: false,
            isPlaceholderData: false,
            isPreviousData: false,
            isRefetchError: false,
            isRefetching: false,
            isStale: true,
            isSuccess: true,
            status: "success",
            data: { code: 200, page: 1, pageSize: 1, total: contentIds.length, docs: contentResults },
          });
        } catch (error) {
          console.error(`Error fetching content: ${error}`);
        }
      };
      fetchClusterContent();
    }
  }, [selectedCluster]);
  console.log(clusterContent);
  console.log(posts);
  const handleFilterChange = (event) => {
    setSelectedCluster(event.target.value);
  };
  const handleClusterClick = (value) => {
    setSelectedCluster(value);
  };

  const urlCategory = `${process.env.REACT_APP_API_URL}/category/statCategory/getStatCategory`;
  const queryNotice = `${process.env.REACT_APP_API_URL}/source/content/lessContent`;
  const [noticeData, setNoticeData] = useState(null);
  const [categoryData, setCategoryData] = useState(null);
  useEffect(() => {
    const getDataCategory = axios
      .get(urlCategory)
      .then((res) => {
        setCategoryData(res?.data?.doc);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error fetching data:", error);
      });
    const getInforNotice = axios
      .get(queryNotice)
      .then((res) => {
        setNoticeData(res?.data?.doc);
      })
      .catch((error) => {
        // Handle any errors that occurred during the request
        console.error("Error fetching data:", error);
      });
  }, []);
  console.log(categoryData);
  console.log(noticeData);
  return (
    <div className="grid">
      <Statistical
        totalTopic={totalTopic}
        totalTodayTopic={totalTodayTopic}
        totalPostFollow={totalPostFollow}
        totalContentToday={totalContentToday}
        totalFBFollow={totalFBFollow}
        totalYTFollow={totalYTFollow}
        totalPostToday={totalPostToday}
        totalWebFollow={totalWebFollow}
        totalContentPerDay={totalContentPerDay}
        categoryData={categoryData}
        noticeData={noticeData}
      />
      {/* <ChartDisplay /> */}
      <DashboardTable
        dataList={clusterContent ? clusterContent : posts}
        handleChangeType={handleChangeType}
        handleChangeSource={handleChangeSource}
        type={type}
        source={source}
        handlePageChange={handlePageChange}
        handleFilterChange={handleFilterChange}
        selectedCluster={selectedCluster}
        handleClusterClick={handleClusterClick}
        params={params}
      />
      {/* <App /> */}
    </div>
  );
};

export default React.memo(Dashboard);
